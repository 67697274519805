<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Client Name</label>
                          <v-select
                            v-model="client"
                            placeholder="None"
                            label="fullname"
                            :options="$store.state.master.customers"
                            multiple
                            :close-on-select="false"
                          >
                            <template #option="{ fullname, profile_image, username }">
                              <b-avatar :src="getprofileImage(profile_image)" />
                              <span class="font-weight-bolder"> {{ fullname }}</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>State</label>
                          <v-select
                            v-model="state"
                            placeholder="None"
                            :options="stateOptions"
                            label="State"
                            @input="handleCity()"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>City</label>
                          <v-select
                            v-model="city"
                            :options="cityOption"
                            label="City"
                            placeholder="None"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <customer-modal
                v-if="$store.state.app.customerDetailsModal"
              ></customer-modal>
              <report-table :data="data" :columns="fields">
                <template #customField="{ props }">
                  <span v-if="props.column.field == 'fullname'" class="cursor-pointer">
                    <div
                      @click="$store.dispatch('app/getCustomerDetails', props.row)"
                      class="text-primary"
                    >
                      {{ props.row.fullname }}
                    </div>
                  </span>
                </template>
                <template #printHeader>
                  <div style="display: flex; justify-content: center; width: 100%">
                    <h3 style="margin: 0px">Customer Data Bank</h3>
                  </div>
                </template>
              </report-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import { BFormGroup, BForm, BRow, BCol, BButton, BAvatar } from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
    BAvatar,
  },
  data() {
    return {
      country: "",
      countryOption: [],
      state: "",
      stateOptions: [],
      city: "",
      cityOption: [],
      branch: "",
      startdate: "",
      enddate: "",
      employee: "",
      employeeoption: [],
      client: [],
      subemployee: "",
      subemployeeoption: [],
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        { field: "fullname", label: "Name" },
        { field: "curstate", label: "State" },
        { field: "curcity", label: "City" },
        { field: "curarea", label: "Area" },
        { field: "curpincode", label: "Pincode" },
        { field: "lands", label: "Land" },
        { field: "projects", label: "Projects" },
        { field: "amount", label: "Invest Amount" },
        { field: "assignto", label: "Assign To" },
        { field: "enterby", label: "Eneter By" },
      ],
    };
  },
  mounted() {
    this.getState();
    this.$store.dispatch("master/getCustomer");
  },
  methods: {
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleCity() {
      this.areaOption = [];
      this.cityOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.state,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.cityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData() {
      const data = {
        state: this.state?.State,
        startdate: this.city?.City,
        client_ids: this.client ? this.client.map((item) => item.id) : [],
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/customerdatabank`,
      }).then((response) => {
        this.data = response.data.data;
        this.data.map((item) => {
          item.assignto = item.assignto[0]?.fullname;
          item.enterby = item.enterby[0]?.fullname;
          item.lands = item.lands ? item.lands : "-";
          item.projects = item.projects ? item.projects : "-";
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
